import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import contactsRoutes from '@/router/modules/contactsRoutes'
import administrationRoutes from '@/router/modules/administrationRoutes'
import operationsRoutes from '@/router/modules/operationsRoutes'
import todosRoutes from '@/router/modules/todosRoutes'
import retroplanningRoutes from '@/router/modules/retroplanningRoutes'
import missionsRoutes from '@/router/modules/missionsRoutes'
import leadsRoutes from '@/router/modules/leadsRoutes'
import formsRoutes from '@/router/modules/formsRoutes'
import exchangesRoutes from '@/router/modules/exchangesRoutes'
import libraryRoutes from '@/router/modules/libraryRoutes'
import landing2Routes from '@/router/modules/landingpage2Routes'
import opportunitiesRoutes from './modules/opportunitiesRoutes'
import GenericDataService from '@/services/GenericDataService'
import { i18n } from '../i18n'
import {func} from '../functions'
import statisticsRoutes from './modules/statisticsRoutes'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      meta: { subMenu: false},
      component: function () {
        return import(/* webpackChunkName: "baseheader" */ '../modules/base/views/BaseHeader.vue')
      },
      children: [
          {
              path: '',
              meta: { subMenu: false},
              component: function () {
                return import(/* webpackChunkName: "basemenu" */ '../modules/base/views/BaseMenu.vue')
              },
              children: [
                  {
                      path: '',
                      name: "Home",
                      meta: { breadCrumb: 'home', titleDocument: "homepage", title:"home", subMenu: false},
                      component: function () {
                          return import(/* webpackChunkName: "home" */ '../modules/base/views/Home.vue')
                      },
                     
                  },
                  ...contactsRoutes,
                  ...operationsRoutes,
                  ...administrationRoutes,
                  ...todosRoutes,
                  ...missionsRoutes,
                  ...leadsRoutes,
                  ...formsRoutes,
                  ...exchangesRoutes,
                  ...libraryRoutes,
                  ...retroplanningRoutes,
                  ...opportunitiesRoutes,
                  ...landing2Routes,
                  ...statisticsRoutes,
                    //TESTS PERSO
                    {
                      path: 'nico-test',
                      name: 'NicoTest',
                      meta: { breadCrumb: '', subMenu: true},
                      component: function () {
                          return import(/* webpackChunkName: "NicoTest" */ '../modules/NicoTest.vue')
                      },
                    },
                    {
                      path: 'tibo-test',
                      name: 'TiboTest',
                      meta: { breadCrumb: '', subMenu: true},
                      component: function () {
                          return import(/* webpackChunkName: "TiboTest" */ '../modules/TiboTest.vue')
                      },
                    },
                    {
                      path: 'dono-test',
                      name: 'DonoTest',
                      meta: { breadCrumb: '', subMenu: true},
                      component: function () {
                          return import(/* webpackChunkName: "TiboTest" */ '../modules/DonoTest.vue')
                      },
                    },
                    {
                      path: '/front-cheatsheet',
                      name: 'FrontCheatsheet',
                      component: function () {
                          return import(/* webpackChunkName: "home" */ '../modules/Home.vue')
                      }
                  },
                  {
                    path: '/erreur-404',
                    name: 'Error404',
                    component: function () {
                        return import(/* webpackChunkName: "home" */ '../components/Error404.vue')
                    }
                },
              ]
          },         
          
      ]

  },
  //...operationsCreationRoutes,
  //...segmentationFormsRoutes,
  //...landingpageRoutes,
  // {
  //   path: '/agendas',
  //   meta: { breadCrumb: 'agenda', titleDocument: "agendaPage", subMenu: false},
  //   redirect:{name: 'GlobalAgenda'},
  //   component: function () {
  //     return import(/* webpackChunkName: "template" */ '../modules/base/views/BasePopupFullscreen.vue')
  //   },
  //   children: [
  //     {
  //         path: ':id?',
  //         name: "GlobalAgenda",
  //         meta: { breadCrumb: 'global', titleDocument: "agendaPage", subMenu: false },
  //         component: function () {
  //             return import(/* webpackChunkName: "template" */ '../modules/agenda/views/AgendaGlobal.vue')
  //         },
  //     },
  //   ]
  // },
  {
    path: '/leaderboard/:operation_id',
    name: 'Leaderboard',
    component: function () {
        return import(/* webpackChunkName: "operationsDetailLeaderboard" */ '../components/LeaderboardComponent.vue')
    }
  },
  {
    path: '/ranking/:token',
    name: 'Ranking',
    component: function () {
        return import(/* webpackChunkName: "operationsDetailRanking" */ '../components/LeaderboardMultiComponent.vue')
    }
  },
  {
    path: '/logout',
    meta: { breadCrumb: '', subMenu: false, titleDocument: "logout"},
    beforeEnter() {
      location.href = 'https://sso.uyb.fr/auth/realms/poc_uys/protocol/openid-connect/logout?post_logout_redirect_uri='+process.env.VUE_APP_URL +"&id_token_hint="+ store.state.auth.idToken
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeResolve((to, from, next) => {
//   if(to.name == 'OperationsDetailDashboard' && from.name == "OperationsDetailDashboard"){
//     GenericDataService.getData('/operation/' + to.params.operation_id + '/get').then((response) => {
//         if(response.data.data.status == "DRAFT"){
//             next({name:'OperationsConfiguration', params:{operation_id: to.params.operation_id}})
//         } else {
//             GenericDataService.getData('/menu/getsubmenu?module=operation&id='+to.params.operation_id).then((response) => {
//                 let submenu = response.data.data;
//                 store.dispatch('base/SET_SUB_MENU', submenu)
//                 next();
//             })
//         }
//     });
//   } else {
//     next();
//   }
  
// })

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.titleDocument);

    if(to.query.account_id) {
        store.dispatch("auth/SET_CURRENT_ACCOUNT_ID", to.query.account_id);

        const d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = "account_id=" + to.query.account_id + ";" + expires + ";path=/";
    }

    if(!store.state.auth.currentUser.parameters) {
        store.dispatch("auth/GET_CURRENT_USER").then((e) => {
            if(!to.meta.rights || func.hasRight(to.meta.rights) || (to.name === 'UserDetail' && to.params.user_id == store.state.auth.currentUser.profile.id)) {
                if(['NicoTest', 'TiboTest', 'FrontCheatsheet'].includes(to.name)) {
                    if(store.state.auth.user.admin_type && store.state.auth.user.admin_type === '1')
                        next();
                    else
                        router.push({name: 'Error404'}).catch(() => {});
                } else {
                    if(nearestWithTitle && store.state.auth.currentUser.accountParameters.name) {
                      document.title = store.state.auth.currentUser.accountParameters.name + " - " + i18n.t(nearestWithTitle.meta.titleDocument.toLowerCase());
                      next();
                    } else {
                      next();
                    } 
                    
                }
            } else {
                router.push({name: 'Error404'}).catch(() => {});
            }
        });
    } else {
        if(!to.meta.rights || func.hasRight(to.meta.rights) || (to.name === 'UserDetail' && to.params.user_id == store.state.auth.currentUser.profile.id)) {
            if(['NicoTest', 'TiboTest', 'FrontCheatsheet'].includes(to.name)) {
                if(store.state.auth.user.admin_type && store.state.auth.user.admin_type === '1')
                    next();
                else
                    router.push({name: 'Error404'}).catch(() => {});
            } else {
                if(nearestWithTitle && store.state.auth.currentUser.accountParameters.name) {
                  document.title = store.state.auth.currentUser.accountParameters.name + " - " + i18n.t(nearestWithTitle.meta.titleDocument.toLowerCase());
                }
                next();
            }
        } else {
            router.push({name: 'Error404'}).catch(() => {});
        } 
    }
});

export default router
